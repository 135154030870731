import React, { useEffect, useState } from "react";
import HeaderAdmin from "../layouts/HeaderAdmin";
import NavbarAdmin from "../components/NavbarAdmin";
import AdminNewUser from "../layouts/AdminNewUser";
import AdminUpdateUser from "../layouts/AdminUpdateUser";
import { isEmpty } from "../assets/Utils";
import { useSelector, useDispatch } from "react-redux";
import { getUser, deleteUser } from "../actions/user.action";
import DeleteConfirmationModal from "../layouts/AdminPopupSuppression";

const AdminUsers = () => {
    const dataUser = useSelector((state) => state.userReducer);
    const dataCity = useSelector((state) => state.cityReducer);
    const [users, setUsers] = useState(dataUser);
    const [selectedUser, setSelectedUser] = useState(null);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [userToDelete, setUserToDelete] = useState(null);
    const dispatch = useDispatch();

    useEffect(() => {
        setUsers(dataUser);
    }, [dataUser]);

    const handleRefreshUsers = () => {
        dispatch(getUser());
        window.alert("Tableau des utilisateurs actualisé.");
    };

    const handleEditUser = (user) => {
        setSelectedUser(user);
    };

    const closeUpdateUserModal = () => {
        setSelectedUser(null);
        dispatch(getUser());
    };

    const handleDeleteClick = (user) => {
        setUserToDelete(user);
        setShowDeleteModal(true);

        const row = document.querySelector(`tr[data-user-id="${user._id}"]`);
        if (row) {
            row.classList.add('deleted');
        }
    };

    const handleConfirmDelete = () => {
        dispatch(deleteUser(userToDelete));
        setShowDeleteModal(false);
        setUsers(users.filter(user => user._id !== userToDelete._id));
        setUserToDelete(null);
    };

    const handleCloseDeleteModal = () => {
        setShowDeleteModal(false);

        const row = document.querySelector(`tr[data-user-id="${userToDelete._id}"]`);
        if (row) {
            row.classList.remove('deleted');
        }
        setUserToDelete(null);
    };

    return (
        <div className="user-admin-container">
            <HeaderAdmin />
            <section className="admin-content-container">
                <NavbarAdmin />
                <div className="admin-content">
                    <AdminNewUser />
                    <section className="user-management">
                        <h2>Gestion des utilisateurs</h2>
                        <button className="btn-refresh" onClick={handleRefreshUsers}>
                            Actualiser <i className="bx bx-refresh"></i>
                        </button>
                        <table className="users-table">
                            <thead>
                            <tr>
                                <th>Nom</th>
                                <th>Prénom</th>
                                <th>Nom d'utilisateur</th>
                                <th>Villes attitrées</th>
                                <th>Niveaux d'administration</th>
                                <th>Modifications</th>
                                <th>Supprimer</th>
                            </tr>
                            </thead>
                            <tbody>
                            {!isEmpty(users) &&
                                users.map((user) => (
                                    <tr key={user._id} data-user-id={user._id}>
                                        <td>{user.lastName}</td>
                                        <td>{user.firstName}</td>
                                        <td>{user.userName}</td>
                                        <td>
                                            {Array.isArray(user.assignedCities) && user.assignedCities.length > 0
                                                ? user.assignedCities
                                                    .map((city) => {
                                                        const cityData = dataCity.find((data) => data.name === city);
                                                        return cityData ? cityData.acronym : "";
                                                    })
                                                    .filter((city) => city !== "")
                                                    .join(", ")
                                                : "Pas de ville"}
                                        </td>
                                        <td>
                                            {Array.isArray(user.userLevels) && user.userLevels.length > 0
                                                ? user.userLevels
                                                    .map((level) => {
                                                        switch (level) {
                                                            case 1:
                                                                return "Administrateur global";
                                                            case 2:
                                                                return "Superviseur";
                                                            case 3:
                                                                return "Rédacteur";
                                                            default:
                                                                return "";
                                                        }
                                                    })
                                                    .join(", ")
                                                : <span style={{ color: "red" }}>Merci d'actualiser la page</span>}
                                        </td>
                                        <td>
                                            <button onClick={() => handleEditUser(user)}>Modifier</button>
                                        </td>
                                        <td>
                                            <button onClick={() => handleDeleteClick(user)}>Supprimer</button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </section>
                </div>
            </section>
            {selectedUser && <AdminUpdateUser user={selectedUser} closePopup={closeUpdateUserModal} />}
            <DeleteConfirmationModal
                show={showDeleteModal}
                onClose={handleCloseDeleteModal}
                onConfirm={handleConfirmDelete}
                who={"cet utilisateur"}
            />
        </div>
    );
};

export default AdminUsers;