import React from "react";
import LogoLbp from "../components/LogoLbp";
import {useSelector} from "react-redux";

const HeaderAdmin = () => {
    const userConnect = useSelector((state) => state.authReducer);

  return (
    <section className="header-admin-container">
      <h1>Bienvenue {userConnect.user.firstName} !</h1>
      <LogoLbp />
    </section>
  );
};

export default HeaderAdmin;
