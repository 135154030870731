import axios from "axios";
import {deleteElement} from "./partnerUploads.action";

export const GET_USER = "GET_USER";
export const GET_USER_BY_ID = "GET_USER_BY_ID";
export const SET_USER = "SET_USER";
export const UPDATE_USER = "UPDATE_USER";
export const DELETE_USER = "DELETE_USER";

export const getUser = () => {
  return async (dispatch) => {
    return axios
        .get("https://api.lesbonsplans.pro" + "/user")
        .then((res) => {
          dispatch({ type: GET_USER, payload: res.data });
        })
        .catch((err) => console.log(err));
  };
};

export const getUserById = (userId) => {
  return async (dispatch) => {
    try {
      const res = await axios.get("https://api.lesbonsplans.pro" + `/user/${userId}`);
      dispatch({ type: GET_USER_BY_ID, payload: res.data });
    } catch (err) {
      console.log(err);
    }
  };
};

export const createUser = (newUser) => {
  return async (dispatch) => {
    try {
      await axios.post("https://api.lesbonsplans.pro" + "/user", newUser);
      dispatch({ type: SET_USER, payload: newUser });
    } catch (err) {
      console.log(err);
    }
  };
};

export const updateUser = (updatedUser) => {
  return async (dispatch) => {
    try {
      await axios.put("https://api.lesbonsplans.pro" + `/user/${updatedUser._id}`, updatedUser);
      dispatch({ type: UPDATE_USER, payload: updatedUser });
    } catch (err) {
      console.log(err);
    }
  };
};

export const deleteUser = (user) => {
  return async (dispatch) => {
    try {
      if(user.logoPressAgency) {
        dispatch (await deleteElement(user.logoPressAgency));
      }
      await axios.delete(`https://api.lesbonsplans.pro/user/${user._id}`);
      dispatch({ type: DELETE_USER, payload: user._id });
    } catch (err) {
      console.log(err);
    }
  };
};