import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Select from "react-select";

const createOption = (label) => ({
  label,
  value: label,
});

const CitySelector = ({ onChange, value, isMulti = false, placeholder, options: propOptions }) => {
  const [options, setOptions] = useState([]);
  const dataCity = useSelector((state) => state.cityReducer);

  useEffect(() => {
    let tempOptions = [];
    if(propOptions) {
      tempOptions = propOptions.map(city => createOption(city));
    }else {
      dataCity.forEach((city) => {
        tempOptions.push(createOption(city.name));
      });
    }
    if(tempOptions.length !== 0) {
      tempOptions.sort((a, b) => a.label.localeCompare(b.label));
      if (isMulti) {
        setOptions([{label: "Sélectionner toutes les villes", value: "select_all"}, ...tempOptions]);
      } else {
        setOptions(tempOptions);
      }
    }
  }, [propOptions, dataCity, isMulti]);


  const handleChange = (selectedOptions) => {
    if (selectedOptions.some(option => option.value === "select_all")) {
      if (selectedOptions.length === options.length) {
        onChange([]);
      } else {
        onChange(options.filter(option => option.value !== "select_all"));
      }
    } else {
      onChange(selectedOptions);
    }
  };

  const customStyles = {
    control: (provided) => ({
      ...provided,
      margin: "20px 0",
      padding: "5px",
      minWidth: "50%",
      backgroundColor: "#e2b259",
      border: "2px solid #fff",
      boxShadow: "none",
      transition: "0.2s ease",
      "&:hover": {
        border: "2px solid #ccc",
      },
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? "#b1852d" : "#e2b259",
      color: state.isFocused ? "white" : "black",
      ":active": {
        backgroundColor: "white",
        color: "black",
      },
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "black",
    }),
    multiValue: (provided) => ({
      ...provided,
      backgroundColor: "#b1852d",
    }),
    multiValueLabel: (provided) => ({
      ...provided,
      color: "white",
    }),
    multiValueRemove: (provided) => ({
      ...provided,
      color: "white",
      "&:hover": {
        backgroundColor: "red",
        color: "white",
      },
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      color: "white",
      transition: "0.2s ease",
      "&:hover": {
        color: "#ccc",
      },
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: "#e2b259",
      border: "2px solid white",
      borderRadius: "4px",
      marginTop: "4px",
      zIndex: 9999,
    }),
  };

  return (
      <Select
          options={options}
          value={value}
          onChange={isMulti ? handleChange: onChange}
          styles={customStyles}
          isMulti={isMulti}
          placeholder={placeholder}
      />
  );
};

export default CitySelector;