import CitySelector from "../components/CitySelector";
import NavbarAdmin from "../components/NavbarAdmin";
import HeaderAdmin from "../layouts/HeaderAdmin";
import { pictureCompressor } from "../assets/utils/pictureCompressor";
import { resetReducer, setLogoPartner } from "../actions/partnerUploads.action";
import React, { useEffect, useState, useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { setNews, getNews } from "../actions/news.action";
import NewsList from "../components/NewsList";

const AdminNews = () => {
    const userConnect = useSelector((state) => state.authReducer);
    const dataUser = useSelector((state) => state.userReducer);
    const dataNews = useSelector((state) => state.newsReducer);
    const contentNewNews = useSelector((state) => state.partnerUploadsReducer);
    const dispatch = useDispatch();

    const [valueCitySelector, setValueCitySelector] = useState([]);
    const [isFormValid, setIsFormValid] = useState(false);
    const [logoFile, setLogoFile] = useState(null);
    const [formData, setFormData] = useState(null);
    const [creationChoose, setCreationChoose] = useState("");
    const [title, setTitle] = useState("");
    const [datePublication, setDatePublication] = useState("");
    const [linkNews, setLinkNews] = useState("");
    const [introduction, setIntroduction] = useState("");
    const [description, setDescription] = useState("");

    useEffect(() => {
        if (creationChoose === "link" && title && datePublication && linkNews && logoFile) {
            setIsFormValid(true);
        } else if (creationChoose === "manual" && title && datePublication && introduction && description && valueCitySelector.length > 0 && logoFile) {
            setIsFormValid(true);
        } else {
            setIsFormValid(false);
        }
    }, [creationChoose, title, datePublication, linkNews, introduction, description, valueCitySelector, logoFile]);

    const handleLogoUpload = useCallback((e) => {
        const file = e.target.files[0];
        setLogoFile(file);
    }, []);

    const handleTitle = useCallback((value) => {
        setTitle(value);
    }, []);

    const handleDatePublication = useCallback((value) => {
        setDatePublication(value);
    }, []);

    const handleLinkNews = useCallback((value) => {
        setLinkNews(value);
    }, []);

    const handleIntroduction = useCallback((value) => {
        setIntroduction(value);
    }, []);

    const handleDescription = useCallback((value) => {
        setDescription(value);
    }, []);

    const handleChangeCitySelector = useCallback((value) => {
        setValueCitySelector(value);
    }, []);

    const handleRefreshNews = () => {
        dispatch(getNews());
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const form = e.target;
        const formData = new FormData(form);
        setFormData(formData);

        if (logoFile) {
            try {
                const formDataLogo = await pictureCompressor(logoFile);
                dispatch(await setLogoPartner(formDataLogo));
            } catch (error) {
                console.error("Error compressing or uploading logo:", error);
            }
        } else {
            submitForm();
        }
    };

    const submitForm = useCallback(() => {
        const data = {
            title: formData.get("title"),
            datePublication: formData.get("datePublication"),
            introduction: formData.get("introduction") || "",
            description: formData.get("description") || "",
            city: valueCitySelector.map((city) => city.value) || [],
            photo_news: contentNewNews.logo,
            link_news: formData.get("lien-news"),
        };
        dispatch(setNews(data, userConnect));
        dispatch(resetReducer());
        setValueCitySelector([]);
        setLogoFile(null);
        setCreationChoose("");
        setTitle("");
        setDatePublication("");
        setLinkNews("");
        setIntroduction("");
        setDescription("");
        handleRefreshNews();
    }, [formData, valueCitySelector, contentNewNews.logo]);

    useEffect(() => {
        if (contentNewNews.logo) {
            submitForm();
        }
    }, [contentNewNews.logo, submitForm]);

    const getAvailableCities = useMemo(() => {
        if (userConnect.user.userLevels[0] === 3) {
            return userConnect.user.assignedCities;
        }
        if (userConnect.user.userLevels[0] === 2) {
            const idUser = userConnect.user.redactors.map((redactor) => redactor);
            const allUser = idUser.map((id) => dataUser.find((user) => user._id === id));
            return allUser.flatMap((user) => user.assignedCities);
        }
        if (userConnect.user.userLevels[0] === 1) {
            return false;
        }
        return [];
    }, [userConnect, dataUser]);

    console.log("data");
    console.log(dataNews);

    const customStylesCitySelector = useMemo(() => ({
        control: (provided) => ({
            ...provided,
            margin: "20px 0",
            padding: "5px",
            backgroundColor: "#e2b259",
            border: "2px solid #fff",
            boxShadow: "none",
            transition: "0.2s ease",
            width: "70%",
            "&:hover": {
                border: "2px solid #ccc",
            },
        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isFocused ? "#b1852d" : "#e2b259",
            color: state.isFocused ? "white" : "black",
            ":active": {
                backgroundColor: "white",
                color: "black",
            },
        }),
        placeholder: (provided) => ({
            ...provided,
            color: "black",
        }),
        dropdownIndicator: (provided) => ({
            ...provided,
            color: "white",
            transition: "0.2s ease",
            "&:hover": {
                color: "#ccc",
            },
        }),
        menu: (provided) => ({
            ...provided,
            backgroundColor: "#e2b259",
            border: "2px solid white",
            borderRadius: "4px",
            marginTop: "4px",
            zIndex: 9999,
            width: "70%",
        }),
        singleValue: (provided) => ({
            ...provided,
            color: "black",
            padding: "2px 8px",
            borderRadius: "4px",
        }),
    }), []);

    return (
        <div className="news-admin-container">
            <HeaderAdmin />
            <section className="admin-content-container">
                <NavbarAdmin />
                <div className="admin-content">
                    <div className="form-and-news-list">
                        {creationChoose && (
                            <NewsList creationChoose={creationChoose} newsList={dataNews} />
                        )}
                        <div className="form-container">
                            <h2>Création d'un nouvel article</h2>
                            <div className="selected">
                                <Select
                                    options={[
                                        { value: "link", label: "Via un lien" },
                                        { value: "manual", label: "Entrer les informations manuellement" },
                                    ]}
                                    onChange={(e) => {
                                        setCreationChoose(e.value);
                                        setValueCitySelector([]);
                                    }}
                                    styles={customStylesCitySelector}
                                    placeholder="Sélectionnez le format de l'article à créer"
                                />
                            </div>
                            <form id="form-new-news" autoComplete="off" onSubmit={handleSubmit}>
                                {creationChoose && (
                                    <section className="new-news-setup">
                                        <h3>Informations</h3>
                                        <div className="info-new-news">
                                            <div className="input-group">
                                                <label htmlFor="title">Titre : </label>
                                                <textarea
                                                    name="title"
                                                    placeholder="Titre de l'article"
                                                    onChange={(e) => handleTitle(e.target.value)}
                                                    required
                                                    rows="3"
                                                />
                                            </div>
                                            <div className="input-group">
                                                <label htmlFor="datePublication">Date et heure de publication : </label>
                                                <input
                                                    type="datetime-local"
                                                    id="datePublication"
                                                    name="datePublication"
                                                    onChange={(e) => handleDatePublication(e.target.value)}
                                                    required
                                                />
                                            </div>
                                            {creationChoose === "manual" && (
                                                <div className="input-group">
                                                    <h3>Ville de publication</h3>
                                                    <CitySelector
                                                        onChange={handleChangeCitySelector}
                                                        value={valueCitySelector}
                                                        isMulti={true}
                                                        placeholder={"Sélectionnez une ou plusieurs villes"}
                                                        options={getAvailableCities}
                                                        required
                                                        styles={customStylesCitySelector}
                                                    />
                                                </div>
                                            )}
                                            {creationChoose === "link" && (
                                                <div className="input-group">
                                                    <label htmlFor="lien-news">Lien de l'article officiel: </label>
                                                    <input
                                                        type="text"
                                                        id="lien-news"
                                                        name="lien-news"
                                                        placeholder="Ajouter un lien vers l'article officiel"
                                                        pattern="https?://.+"
                                                        onChange={(e) => handleLinkNews(e.target.value)}
                                                        required
                                                    />
                                                </div>
                                            )}
                                        </div>
                                    </section>
                                )}
                                {creationChoose === "manual" && (
                                    <section className="new-news-content">
                                        <div className="description-new-news">
                                            <label htmlFor="introduction" id="label-introduction">
                                                Paragraphe d'introduction :
                                            </label>
                                            <textarea
                                                id="introduction"
                                                name="introduction"
                                                placeholder="Rédigez le paragraphe d'introduction de l'article"
                                                onChange={(e) => handleIntroduction(e.target.value)}
                                                required
                                            />
                                        </div>
                                        <div className="description-new-news">
                                            <label htmlFor="description" id="label-description">
                                                Rédaction de l'article :
                                            </label>
                                            <textarea
                                                id="description"
                                                name="description"
                                                placeholder="Rédigez le corps de l'article"
                                                onChange={(e) => handleDescription(e.target.value)}
                                                required
                                            />
                                        </div>
                                    </section>
                                )}
                                {creationChoose && (
                                    <section className="new-news-content">
                                        <div className="new-news-content-container">
                                            <label htmlFor="logo-content">
                                                {!logoFile
                                                    ? "Ajouter une photo de l'article"
                                                    : "Modifier la photo de l'article"}
                                            </label>
                                            <input
                                                type="file"
                                                id="logo-content"
                                                onChange={handleLogoUpload}
                                                hidden
                                                required
                                            />
                                            {creationChoose === "manual" && (
                                                <div className="links-section">
                                                    <div className="link-input">
                                                        <label htmlFor="lien-news">Lien de l'article officiel: </label>
                                                        <input
                                                            type="text"
                                                            id="lien-news"
                                                            name="lien-news"
                                                            placeholder="Ajouter un lien vers l'article officiel"
                                                            pattern="https?://.+"
                                                            onChange={(e) => handleLinkNews(e.target.value)}
                                                            required
                                                        />
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </section>
                                )}
                                {logoFile && (
                                    <section className="preview">
                                        <h3>Prévisualisation</h3>
                                        <div className="uploaded-logo-photo">
                                            {logoFile && creationChoose === "manual" && (
                                                <div className="uploaded-logo-photo-contain">
                                                    <h3>Logo ajouté</h3>
                                                    <img
                                                        src={URL.createObjectURL(logoFile)}
                                                        alt="Logo ajouté"
                                                        width={200}
                                                    />
                                                </div>
                                            )}
                                            {(logoFile || title) && creationChoose === "link" && (
                                                <div className="uploaded-logo-photo-contain link-preview">
                                                    {logoFile && (
                                                        <img src={URL.createObjectURL(logoFile)} alt="Preview"
                                                             width={200} />
                                                    )}
                                                    <div className="link-preview-text">
                                                        <h3>{title ? title : null}</h3>
                                                    </div>
                                                    <div className="link-preview-img-date">
                                                        <img src={
                                                            "https://api.lesbonsplans.pro" +
                                                            "/upload/upload/image/" +
                                                            userConnect.user.logoPressAgency
                                                        } alt={userConnect.user.logoPressAgency} width="100%" />
                                                        <p className="date-publication">{new Date(datePublication).toLocaleDateString()}</p><p> {new Date(datePublication).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}</p>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </section>
                                )}
                                {creationChoose && (
                                    <input
                                        id={isFormValid ? "valid" : "invalid"}
                                        type="submit"
                                        value="Créer l'article"
                                    />
                                )}
                            </form>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default AdminNews;