import React, { useEffect, useState } from "react";
import Header from "../layouts/Header";
import PartnerListEntry from "../components/PartnerListEntry";
import { useSelector } from "react-redux";
import { isEmpty } from "../assets/Utils";
import { useParams } from "react-router";
import { getDistance } from "geolib";
import Cookies from 'js-cookie';

const PartnersList = () => {
    const { citysubDomain, categorysubDomain } = useParams();
    const dataPartner = useSelector((state) => state.partnerReducer);
    const [currentCity, setCurrentCity] = useState({});
    const [currentCategory, setcurrentCategory] = useState({});
    const [filteredPartner, setFilteredPartner] = useState([]);
    const [currentLocation, setCurrentLocation] = useState(null);
    const [sortedPartner, setSortedPartner] = useState([]);
    const [visibleCount, setVisibleCount] = useState(5);

    const collectDataLocation = (pos) => {
        const crd = pos.coords;
        const data = {
            latitude: crd.latitude,
            longitude: crd.longitude,
        };
        Cookies.set("currentLocation", JSON.stringify(data), { expires: 1/96 }); // 15 minutes
        setCurrentLocation(data);
    };

    const checkCookieForLocation = () => {
        const savedLocation = Cookies.get("currentLocation");
        if (savedLocation) {
            const { latitude, longitude } = JSON.parse(savedLocation);
            setCurrentLocation({ latitude, longitude });
            return true;
        }
        return false;
    };

    const handleLocationError = () => {
        setCurrentLocation(null);
    };

    useEffect(() => {
        if (!checkCookieForLocation()) {
            navigator.geolocation.getCurrentPosition(collectDataLocation, handleLocationError);
        }
    }, []);

    useEffect(() => {
        const fetchCity = async () => {
            try {
                let correctedSubDomain = citysubDomain;
                if (correctedSubDomain === 'lagrandemotte') {
                    correctedSubDomain = 'la-grande-motte';
                }
                if (correctedSubDomain === 'legrauduroi') {
                    correctedSubDomain = 'le-grau-du-roi';
                }
                const response = await fetch(`https://api.lesbonsplans.pro/city/search?subDomain=${correctedSubDomain}`);
                const data = await response.json();
                setCurrentCity(data);
            } catch (error) {
                console.error("Error fetching city data:", error);
            }
        };

        fetchCity();
    }, [citysubDomain]);

    useEffect(() => {
        const fetchCategory = async () => {
            if (!isEmpty(currentCity)) {
                try {
                    const response = await fetch(`https://api.lesbonsplans.pro/category/search?subDomain=${categorysubDomain}`);
                    const data = await response.json();
                    setcurrentCategory(data);
                } catch (error) {
                    console.error("Error fetching category data:", error);
                }
            }
        };

        fetchCategory();
    }, [categorysubDomain, currentCity]);

    useEffect(() => {
        const fetchPartner = async () => {
            if (!isEmpty(currentCity) && !isEmpty(currentCategory) && currentCategory.name) {
                try {
                    const response = await fetch(`https://api.lesbonsplans.pro/partner/searchByCityAndCategory?cityId=${currentCity._id}&categoryName=${currentCategory.name}`);
                    const data = await response.json();
                    setFilteredPartner(data.filter(part => part.isActive));
                } catch (error) {
                    console.error('Error fetching partners:', error);
                }
            }
        };

        fetchPartner();
    }, [currentCity, currentCategory]);

    useEffect(() => {
        if (!isEmpty(filteredPartner)) {
            const partnersWithDistance = filteredPartner.map((partner) => {
                if (currentLocation && partner.coordinates && partner.coordinates.length === 2 && partner.coordinates[0] !== null && partner.coordinates[1] !== null) {
                    const distance = getDistance(
                        { latitude: currentLocation.latitude, longitude: currentLocation.longitude },
                        { latitude: partner.coordinates[0], longitude: partner.coordinates[1] }
                    ) / 1000;
                    return { ...partner, distance };
                } else {
                    return { ...partner, distance: null };
                }
            });

            const sortedPartners = partnersWithDistance.sort((a, b) => {
                if (a.distance !== null && b.distance !== null) {
                    return a.distance - b.distance;
                } else if (a.distance === null && b.distance === null) {
                    return a.name.localeCompare(b.name);
                } else {
                    return a.distance !== null ? -1 : 1;
                }
            });

            setSortedPartner(sortedPartners);
        }
    }, [filteredPartner, currentLocation]);

    const loadMore = () => {
        setVisibleCount(prevCount => prevCount + 5);
    };

    const cityBackground = {
        background: !isEmpty(currentCity) && currentCity.picture
            ? `url(${"https://api.lesbonsplans.pro"}/upload/city/image/${currentCity.picture}) fixed center bottom/cover`
            : 'none',

    };


    return (
        <div className="partners-list-container" style={cityBackground}>
            <Header navPath="categories" stringBtn="Retourner aux catégories"/>
            <h1>{currentCategory.name}</h1>
            <section className="partners-list">
                {!isEmpty(sortedPartner) &&
                    sortedPartner.slice(0, visibleCount).map((part) => (
                        <PartnerListEntry key={part._id} partner={part} distance={part.distance ? part.distance.toFixed(2) : 'N/A'} />
                    ))
                }
            </section>
            {visibleCount < sortedPartner.length && (
                <button onClick={loadMore} className="load-more-button">Afficher plus</button>
            )}
        </div>
    );
};

export default PartnersList;