import React from "react";
import { NavLink } from "react-router-dom";
import { useSelector } from "react-redux";

const NavbarAdmin = () => {
  const adminLevels = useSelector(
    (state) => state.authReducer.user.userLevels
  );

  return (
    <nav className="nav-admin-container">
      <ul>
        {adminLevels.includes(1) && (
          <li title="Création / modification d'utilisateurs administrateurs">
            <NavLink
              to="/administration/utilisateurs"
              className={(nav) => (nav.isActive ? "nav-active" : "")}
            >
              Utilisateurs
            </NavLink>
          </li>
        )}
          {adminLevels.includes(1) && (
          <li title="Création / modification de villes">
            <NavLink
              to="/administration/gestion-villes"
              className={(nav) => (nav.isActive ? "nav-active" : "")}
            >
              Gérer villes
            </NavLink>
          </li>
        )}
          {adminLevels.includes(1) && (
          <li title="Création / modification du contenu dans les villes">
            <NavLink
              to="/administration/contenu-ville"
              className={(nav) => (nav.isActive ? "nav-active" : "")}
            >
              Contenu ville
            </NavLink>
          </li>
        )}
          {adminLevels.includes(1) && (
          <li title="Création / modification de partenaires">
            <NavLink
              to="/administration/partenaires"
              className={(nav) => (nav.isActive ? "nav-active" : "")}
            >
              Partenaires
            </NavLink>
          </li>
        )}
          {adminLevels.includes(1) && (
          <li title="Envoi de message">
            <NavLink
              to="/administration/notifications"
              className={(nav) => (nav.isActive ? "nav-active" : "")}
            >
              Messages
            </NavLink>
          </li>
        )}
          {adminLevels.includes(1) && (
          <li title="Statistiques du site web">
            <NavLink
              to="/administration/statistiques"
              className={(nav) => (nav.isActive ? "nav-active" : "")}
            >
              Statistiques
            </NavLink>
          </li>
        )}
          {(adminLevels.includes(1) || adminLevels.includes(2) || adminLevels.includes(3)) && (
              <li title="Création de news">
                  <NavLink
                      to="/administration/actualites"
                      className={(nav) => (nav.isActive ? "nav-active" : "")}
                  >
                      Création des news
                  </NavLink>
              </li>
          )}
          {(adminLevels.includes(1) || adminLevels.includes(2) || adminLevels.includes(3)) && (
              <li title="Gestions news et rédacteurs">
                  <NavLink
                      to="/administration/gestionActualites"
                      className={(nav) => (nav.isActive ? "nav-active" : "")}
                  >
                      Gestion des news
                  </NavLink>
              </li>
          )}
      </ul>
    </nav>
  );
};

export default NavbarAdmin;
