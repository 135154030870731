import axios from "axios";
import { UPDATE_USER } from "./user.action";

export const GET_NEWS = "GET_NEWS";
export const SET_NEWS = "SET_NEWS";
export const UPDATE_NEWS = "UPDATE_NEWS";
export const DELETE_NEWS = "DELETE_NEWS";

export const getNews = () => {
    return async (dispatch) => {
        return axios
            .get("https://api.lesbonsplans.pro/news")
            .then((res) => {
                dispatch({ type: GET_NEWS, payload: res.data });
            })
            .catch((err) => console.log(err));
    };
};

export const setNews = (news, userConnect) => {
    return async (dispatch) => {
        return axios
            .post("https://api.lesbonsplans.pro/news", news)
            .then((res) => {
                const newsId = res.data._id;
                dispatch({ type: SET_NEWS, payload: res.data });

                const { password, ...userWithoutPassword } = userConnect.user;
                const updatedUser = {
                    ...userWithoutPassword,
                    news: [...userWithoutPassword.news, newsId]
                };

                return axios
                    .put("https://api.lesbonsplans.pro/user/" + userConnect.user._id, updatedUser)
                    .then((res) => {
                        dispatch({ type: UPDATE_USER, payload: res.data });
                    })
                    .catch((err) => console.log(err));
            })
            .catch((err) => console.log(err));
    };
};

export const updateNews = (news) => {
    return async (dispatch) => {
        return axios
            .put("https://api.lesbonsplans.pro/news/" + news._id, news)
            .then((res) => {
                dispatch({
                    type: UPDATE_NEWS,
                    payload: { ...res.data, ...news },
                });
            })
            .catch((err) => console.log(err));
    };
};

export const deleteNews = (news) => {
    return async (dispatch) => {
        return axios
            .delete("https://api.lesbonsplans.pro/news/" + news._id)
            .then((res) => {
                dispatch({
                    type: DELETE_NEWS,
                    payload: news._id,
                });
            })
            .catch((err) => console.log(err));
    };
};