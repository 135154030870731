import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import CityListEntry from "../components/CityListEntry";
import LogoLbp from "../components/LogoLbp";
import InstallPWA from "../components/InstallPwa";
import GeoLocationInstructions from "../components/GeoLocationInstructions";
import { getDistance } from "geolib";
import Cookies from 'js-cookie';


const HomeRedirect = () => {
    const dataCity = useSelector((state) => state.cityReducer);
    const [closestCity, setClosestCity] = useState(null);
    const [currentLocation, setCurrentLocation] = useState({});
    const [geoError, setGeoError] = useState(false);
    const [geoPrompt, setGeoPrompt] = useState(false);
    const [retry, setRetry] = useState(0);
    const navigate = useNavigate();

    const saveLocationToCookie = (location) => {
        const data = {
            latitude: location.latitude,
            longitude: location.longitude,
        };
        Cookies.set("currentLocation", JSON.stringify(data), { expires: 1/96 }); // 15 minutes
    };

    useEffect(() => {
        const checkGeolocationPermission = async () => {
            try {
                const permissionStatus = await navigator.permissions.query({ name: 'geolocation' });
                if (permissionStatus.state === 'prompt') {
                    setGeoPrompt(true);
                }
            } catch (error) {
                console.error("Error checking geolocation permission:", error);
            }
        };

        checkGeolocationPermission();

        const savedLocation = Cookies.get("currentLocation");
        if (savedLocation) {
            const { latitude, longitude } = JSON.parse(savedLocation);
            setCurrentLocation({ latitude, longitude });
            findAndSetClosestCity(latitude, longitude);
        } else if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const { latitude, longitude } = position.coords;
                    saveLocationToCookie({ latitude, longitude });
                    setCurrentLocation({ latitude, longitude });
                    findAndSetClosestCity(latitude, longitude);
                    setGeoPrompt(false);
                },(error) => {
                    if (error.code === error.PERMISSION_DENIED) {
                        setGeoError(true);
                        setGeoPrompt(false);
                        console.log("Geolocation permission denied");
                    } else {
                        setRetry(retry + 1);
                    }
                },
                {
                    enableHighAccuracy: false,
                    timeout: 5000,
                    maximumAge: 0,
                }
            );
        }
    }, [dataCity, retry]);

    const findAndSetClosestCity = (latitude, longitude) => {
        if (Array.isArray(dataCity)) {
            const closest = findClosestCity(dataCity, latitude, longitude);
            setClosestCity(closest);
        } else {
            console.error("dataCity is not an array");
        }
    };

    const findClosestCity = (cities, userLat, userLng) => {
        let closest = null;
        let minDistance = Infinity;

        cities
            .filter((city) => city.isActive) // Consider only active cities
            .forEach((city) => {
                const [cityLat, cityLng] = city.coordinates;
                const distance = getDistance(
                    { latitude: userLat, longitude: userLng },
                    { latitude: cityLat, longitude: cityLng }
                ) / 1000;
                if (distance < minDistance) {
                    minDistance = distance;
                    closest = city;
                }
            });

        return closest;
    };

    const handleButtonClick = () => {
        navigate("/selection-ville", { state: { dataCity } });
    };

    return (
        <div className="home-redirect-container">
            <InstallPWA />
            <section className="logo-container">
                <LogoLbp />
            </section>
            <section className="city-selector">
                {geoError ? (
                    <GeoLocationInstructions />
                ) : null}
                <div className="city-display">
                    {closestCity ? (
                        <CityListEntry key={closestCity._id} city={closestCity} currentLocation={currentLocation} />
                    ) : (
                        !geoError ? (
                            retry !== 0 ? <div className="button-cities-container "><p className="problemPosition">Vous êtes dans une zone de mauvaise réception. Merci de patienter quelques instants ou de cliquer sur "Choisir une autre ville" afin d'indiquer la ville souhaitée dans la barre de recherche blanche</p></div>
                                : (geoPrompt ? <div className="button-cities-container"><p className="errorPosition">Merci d'autoriser la géolocalisation qui vous est proposée pour une expérience utilisateur encore meilleur !</p></div>
                                    : <div className="button-cities-container"><p>Chargement de la ville la plus proche...</p></div>)
                        ) : null
                    )}
                    <div className="other-cities">
                        <div className="button-cities-container">
                            <button onClick={handleButtonClick}>
                                <i className="bx bxs-buildings"></i>
                                <span>Choisir une autre ville</span>
                            </button>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default HomeRedirect;